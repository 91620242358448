import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import CheckIcon from '@material-ui/icons/Check';
import { Field, Form, Formik } from 'formik';
import Image from 'next/image';
import { TFunction } from 'next-i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Bugsnag from 'lib/bugsnag';
import { contactListSubscribe } from 'lib/mailjet';
import { Mouseflow } from 'lib/mouseflow';
import Yup from 'lib/yup';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';
import FormikCheckbox from 'shared/components/Formik/FormikCheckbox';
import FormikFormField from 'shared/components/FormikFormField';

const FORM_ID = 'sign-up-form';

interface Props {
  imageSrc?: string;
  title?: string;
  subTitle?: string;
  successFeedback?: string;
  buttonText?: string;
  contactListId?: number;
}

const createFormSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string()
      .required(t('common:signUpForm.emailRequired'))
      .email(t('common:signUpForm.correctFormatEmail')),
    acceptSubscription: Yup.bool()
      .oneOf([true], t('common:validations.required'))
      .required(),
  });

type FormValues = Yup.InferType<ReturnType<typeof createFormSchema>>;

const DEFAULT_FORM_VALUES: FormValues = {
  email: '',
  acceptSubscription: false,
};

export default function SignUpForm({
  imageSrc,
  title,
  buttonText,
  subTitle,
  successFeedback,
  contactListId,
}: Props) {
  const { t } = useTranslation('hub');
  const mobile = useBreakpointDown('sm');
  const [submissionResult, setSumbissionResult] = useState<
    null | 'success' | 'failure'
  >(null);

  const closeFeedbackMessageTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!submissionResult) {
      return;
    }
    closeFeedbackMessageTimeoutRef.current = setTimeout(() => {
      setSumbissionResult(null);
    }, 6000);

    return () => {
      if (closeFeedbackMessageTimeoutRef.current) {
        clearTimeout(closeFeedbackMessageTimeoutRef.current);
      }
    };
  }, [submissionResult]);

  const hasImage = !!imageSrc;

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={'column'}
    >
      {hasImage && (
        <ImageWrapper>
          <Image
            alt=""
            src={imageSrc}
            width={690}
            height={280}
            objectFit="cover"
          />
        </ImageWrapper>
      )}
      <ContentWrapper>
        <Box mb={{ xs: 3, md: 2 }} textAlign="left">
          <Typography
            variant="h3"
            lineHeight="146%"
            mb={{ xs: 2, md: 2 }}
            mt={2}
            fontSize={{ xs: '24px', md: '24px' }}
          >
            {title || t('common:signUpForm.title')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {subTitle || t('common:signUpForm.subtitle')}
          </Typography>
        </Box>

        <Formik
          initialValues={DEFAULT_FORM_VALUES}
          validationSchema={createFormSchema(t)}
          onSubmit={async (values, actions) => {
            try {
              setSumbissionResult(null);
              Mouseflow.notify.formSubmitAttempt(FORM_ID);
              await contactListSubscribe(values.email, contactListId);
              Mouseflow.notify.formSubmitSuccess(FORM_ID);
              setSumbissionResult('success');
            } catch (e) {
              if (e instanceof Error) {
                Mouseflow.notify.formSubmitFailure(FORM_ID);
                Bugsnag.notify(e);
              }
              setSumbissionResult('failure');
            } finally {
              actions.setSubmitting(false);
              actions.resetForm({ values: DEFAULT_FORM_VALUES });
            }
          }}
          enableReinitialize
        >
          {() => {
            return (
              <StyledForm id={FORM_ID}>
                <Typography
                  variant={mobile ? 'label' : 'srOnly'}
                  component="label"
                  fontSize={{ xs: 12 }}
                  fontWeight={500}
                  htmlFor="email"
                  ml={1}
                >
                  {'Email *'}
                </Typography>

                <InputAndButtonWrapper>
                  <Box maxWidth="600px">
                    <Field
                      placeholder={'Email'}
                      name="email"
                      id="email"
                      type="email"
                      component={FormikFormField}
                    />
                  </Box>
                  <StyledButton
                    size="lg"
                    variant="primary"
                    type="submit"
                    role="button"
                    aria-label="submit"
                    id="contact-caterer-modal-submit"
                  >
                    {buttonText || t('common:signUpForm.button')}
                  </StyledButton>
                </InputAndButtonWrapper>

                {submissionResult && (
                  <Box alignSelf="center">
                    {submissionResult === 'success' ? (
                      <StyledBox $type="success">
                        <CheckIcon color="inherit" />
                        <Typography variant="caption" color="inherit" ml={1}>
                          {successFeedback ||
                            t('common:signUpForm.successMessage')}
                        </Typography>
                      </StyledBox>
                    ) : (
                      <StyledBox $type="failure">
                        <Typography variant="caption" color="inherit" ml={1}>
                          {t('common:signUpForm.errorMessage')}
                        </Typography>
                      </StyledBox>
                    )}
                  </Box>
                )}
                <Box>
                  <FormikCheckbox
                    name="acceptSubscription"
                    size="small"
                    role="checkbox"
                    aria-label="Checkbox"
                    label={
                      <Typography
                        variant="body2"
                        fontSize={{ xs: 12, md: 14 }}
                        pt={0.25}
                        pl={1}
                      >
                        {t('common:signUpForm.checkboxLabel')}
                      </Typography>
                    }
                  />
                </Box>
              </StyledForm>
            );
          }}
        </Formik>
        <FooterSocialLinks>
          <FooterSocialIconLink
            href={'https://www.facebook.com/heycater'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="facebook logo"
              src={'/artifacts/images/hub/socials/facebook.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <FooterSocialIconLink
            href={'https://www.linkedin.com/company/heycater/'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="linkedin logo"
              src={'/artifacts/images/hub/socials/linkedin.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <FooterSocialIconLink
            href={'https://www.instagram.com/heycater/'}
            target="_blank"
            rel="noopener"
          >
            <Image
              alt="instagram logo"
              src={'/artifacts/images/hub/socials/instagram.svg'}
              width={40}
              height={40}
            />
          </FooterSocialIconLink>
          <Typography fontWeight={500} variant="caption">
            {t('cta.ctaSignupSocialsText')}
          </Typography>
        </FooterSocialLinks>
        <div>
          <Box display="flex" alignItems="center" justifyContent="center"></Box>
        </div>
      </ContentWrapper>
    </Box>
  );
}

const FooterSocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(5)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: ${({ theme }) => theme.spacing(3)}px;
  }
`;

const StyledForm = styled(Form)`
  .hc-form-control {
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  img {
    border-radius: ${(props) => props.theme.spacing(3)}px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  p {
    margin: 0;
  }
`;

const InputAndButtonWrapper = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing(1)}px;

  div {
    width: 100%;
  }

  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    gap: ${(props) => props.theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 52px; // So it's the same height as the input field next to it.
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

const FooterSocialIconLink = styled.a`
  color: inherit;
  cursor: pointer;
  display: contents;
`;

const StyledBox = styled.div<{ $type: 'success' | 'failure' }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme, $type }) =>
    $type === 'success'
      ? theme.palette.secondary.light
      : theme.palette.error.light};

  padding: ${(props) => props.theme.spacing(1, 1, 1, 1)};
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  width: 100%;
  border-radius: ${(props) => props.theme.spacing(1)}px;
  color: ${(props) => props.theme.palette.common.white};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;
