/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import { Box } from '@heycater/design-system';
import { Asset } from 'contentful';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import {
  CONTENT_TYPE,
  IButtonFields,
  IFaqListFields,
  IHtmlSnippetFields,
  IHubBannerFields,
  IImageGalleryFields,
  IPersonCtaFields,
  TestimonialsFields,
} from 'lib/types/generated/contentful';

const Accordion = dynamic(() => import('shared/components/Accordion'));
const ContentfulButton = dynamic(
  () => import('hub/components/ContentfulButton')
);
const ContentfulImage = dynamic(() => import('hub/components/ContentfulImage'));
const ExpertSection = dynamic(() => import('hub/components/ExpertSection'));
const HTMLSnippet = dynamic(() => import('hub/components/HTMLSnippet'));
const HubBanner = dynamic(() => import('hub/components/HubBanner'));
const ImageGallery = dynamic(() => import('hub/components/ImageGallery'));
const VideoEmbed = dynamic(() => import('hub/components/VideoEmbed'));

const Testimonials = dynamic(() => import('shared/components/Testimonials'));
const FullWidthMedia = dynamic(
  () => import('static_pages/dynamic_page/components/FullWidthMedia')
);
const HeroFunnel = dynamic(
  () => import('static_pages/dynamic_page/components/HeroFunnel')
);
const ImageGalleryWithCaption = dynamic(
  () => import('static_pages/dynamic_page/components/ImageGalleryWithCaption')
);
const ImageTextFlexBox = dynamic(
  () => import('static_pages/dynamic_page/components/ImageTextFlexBox')
);
const MPPreviewCards = dynamic(
  () => import('static_pages/dynamic_page/components/MPPreviewCards')
);
const Services = dynamic(
  () => import('static_pages/dynamic_page/components/Services')
);
const TitleWithCaption = dynamic(
  () => import('static_pages/dynamic_page/components/TitleWithCaption')
);

import SignUpForm from 'hub/components/ArticlePage/SignupForm';

export function getContentfulImageUrl(contentfulImage: Asset) {
  return `https:${contentfulImage.fields.file.url}`;
}

export function embeddedAssetOptions(props: any = { ratings: undefined }) {
  const ratings = props?.ratings;

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        const contentType = node.data.target.sys.contentType.sys
          .id as CONTENT_TYPE;

        if (contentType === 'imageGallery') {
          const fields = node.data.target.fields as IImageGalleryFields;
          return (
            <ImageGallery
              images={fields.images.map((image) => ({
                src: getContentfulImageUrl(image),
                alt: image.fields.description,
              }))}
            />
          );
        }
        if (contentType === 'hubBanner') {
          const fields = node.data.target.fields as IHubBannerFields;
          return (
            <BlockEntryWrapper>
              <HubBanner
                title={fields.titleContent}
                button={{ text: fields.buttonText, url: fields.url }}
                subtitle={fields.subtitle}
                image={
                  fields.image
                    ? { src: getContentfulImageUrl(fields.image), alt: '' }
                    : undefined
                }
              />
            </BlockEntryWrapper>
          );
        }
        if (contentType === 'htmlSnippet') {
          const fields = node.data.target.fields as IHtmlSnippetFields;

          return <HTMLSnippet htmlSnippet={fields.htmlSnippet} />;
        }

        if (contentType === 'personCta') {
          const fields = node.data.target.fields as IPersonCtaFields;
          const personFields = fields.person.fields;
          const description = fields.description || personFields?.description;
          return (
            <Box maxWidth={1050} mx="auto">
              <ExpertSection
                my={4}
                ctaLabel={fields.ctaText}
                ctaLink={fields.ctaUrl}
                description={description || ''}
                imageSrc={getContentfulImageUrl(personFields.avatar)}
                name={personFields.name}
                title={fields.title}
              />
            </Box>
          );
        }

        if (contentType === 'embeddedQualificationFunnel') {
          return <HeroFunnel {...node.data.target.fields} ratings={ratings} />;
        }

        if (contentType === 'contactForm') {
          const contactForm = { ...node.data.target.fields };
          return (
            <Box maxWidth={800} mx="auto">
              <SignUpForm
                imageSrc={
                  contactForm.image
                    ? getContentfulImageUrl(contactForm.image)
                    : undefined
                }
                title={contactForm.title}
                buttonText={contactForm.buttonText}
                subTitle={contactForm.subtitle}
                successFeedback={contactForm.successFeedback}
                contactListId={contactForm.contactList.fields.id}
              />
            </Box>
          );
        }

        if (contentType === 'titleWithCaption') {
          return <TitleWithCaption {...node.data.target.fields} />;
        }

        if (contentType === 'imageGalleryWithCaption') {
          return <ImageGalleryWithCaption {...node.data.target.fields} />;
        }

        if (contentType === 'imageTextFlexBox') {
          return <ImageTextFlexBox {...node.data.target.fields} />;
        }

        if (contentType === 'services') {
          return <Services {...node.data.target.fields} />;
        }

        if (contentType === 'mpPreviewCards') {
          return <MPPreviewCards {...node.data.target.fields} />;
        }

        if (contentType === 'fullWidthMedia') {
          return <FullWidthMedia {...node.data.target.fields} />;
        }

        if (contentType === 'testimonialList') {
          const { testimonials = [] } = node.data.target
            .fields as TestimonialsFields;
          return (
            <Box maxWidth={1050} mx="auto">
              <Testimonials
                items={testimonials.map(({ fields: testimonial }) => ({
                  imageSrc: testimonial.image
                    ? getContentfulImageUrl(testimonial.image)
                    : '',
                  body: testimonial.body,
                  company: {
                    name: testimonial.company,
                    image: testimonial.companyLogo
                      ? {
                          src: getContentfulImageUrl(testimonial.companyLogo),
                          height:
                            testimonial.companyLogo.fields.file.details.image
                              ?.height,
                          width:
                            testimonial.companyLogo.fields.file.details.image
                              ?.width,
                        }
                      : undefined,
                  },
                  title: testimonial.title,
                  name: testimonial.name,
                }))}
              />
            </Box>
          );
        }

        if (contentType === 'testimonial') {
          const testimonial = { ...node.data.target.fields };
          return (
            <Box maxWidth={1050} mx="auto">
              <Testimonials
                items={[
                  {
                    imageSrc: getContentfulImageUrl(testimonial.image),
                    body: testimonial.body,
                    company: {
                      name: testimonial.company,
                      image: testimonial.companyLogo
                        ? {
                            src: getContentfulImageUrl(testimonial.companyLogo),
                            height:
                              testimonial.companyLogo.fields.file.details.image
                                ?.height,
                            width:
                              testimonial.companyLogo.fields.file.details.image
                                ?.width,
                          }
                        : undefined,
                    },
                    title: testimonial.title,
                    name: testimonial.name,
                  },
                ]}
              />
            </Box>
          );
        }

        if (contentType === 'faqList') {
          const fields = node.data.target.fields as IFaqListFields;
          const faqs = fields.items;

          return (
            <Box maxWidth={1050} mx="auto">
              {faqs.map((faq) => {
                return (
                  <Accordion
                    key={faq.sys.id}
                    details={documentToReactComponents(faq.fields.answer)}
                    summary={faq.fields.question}
                  />
                );
              })}
            </Box>
          );
        }

        if (contentType === 'button') {
          const fields = node.data.target.fields as IButtonFields;
          return <ContentfulButton {...fields} />;
        }
      },
      [BLOCKS.HEADING_1]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h1">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.HEADING_2]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h2">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.HEADING_3]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h3">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.HEADING_4]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h4">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.HEADING_5]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h5">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.HEADING_6]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box width="100%" maxWidth={1050} mx="auto">
            <Box as="h6">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => {
        return (
          <Box maxWidth={1050} mx="auto">
            <Box as="p">{children}</Box>
          </Box>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        const { file } = node.data.target.fields;
        const { contentType } = file;
        const [mimeGroup] = contentType.split('/');

        if (mimeGroup === 'image') {
          return (
            <ContentfulImage
              src={node.data.target}
              width={file.details.image.width}
              height={file.details.image.height}
              layout="responsive"
            />
          );
        }
      },
      [INLINES.HYPERLINK]: (node: Block | Inline) => {
        const url = node.data.uri;
        if (
          url.includes('vimeo.com') ||
          url.includes('youtube.com') ||
          url.includes('youtu.be')
        ) {
          return <VideoEmbed url={url} />;
        }
        //@ts-expect-error TODO: figure out what happens here
        return <a href={url}>{node.content[0].value}</a>;
      },
    },
  };
}

const BlockEntryWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(4)}px auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:first-child {
      margin-top: ${({ theme }) => theme.spacing(0)}px;
    }
  }
`;
