import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const Textarea = forwardRef((props, ref) => (
  <StyledTextarea ref={ref} multiline rows={4} variant="filled" {...props} />
));

Textarea.displayName = 'Textarea';

const StyledTextarea = styled(TextField)`
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 8px;
    padding: 12px;
    background-color: ${(props) => props.theme.palette.text.disabled};
    border: 1px solid transparent;
    line-height: ${(props) => props.theme.typography.body1.lineHeight};

    &:hover {
      background-color: ${(props) => props.theme.palette.text.disabled};
      border: 1px solid ${(props) => props.theme.palette.grey[400]};
    }

    &:active,
    &:focus,
    &:focus-visible,
    &.Mui-focused {
      border: 1px solid ${(props) => props.theme.palette.grey[600]};
    }
  }

  .MuiFilledInput-underline:before {
    border-bottom: none;
  }
  .MuiFilledInput-underline:after {
    border-bottom: none;
  }

  .MuiInputBase-inputMultiline {
    cursor: auto;
  }
`;

export default Textarea;
