import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

export default function useFormikFormField({
  field,
  form: { errors, touched },
  helperText,
}) {
  const { t } = useTranslation();

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;
  const formHelperText = (showError && t(fieldError)) || helperText;

  return {
    showError,
    formHelperText,
  };
}
