import React from 'react';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@heycater/design-system';

import useFormikFormField from 'shared/hooks/useFormikFormField';
import Textarea from 'system/components/Textarea';

function FormikFormField({
  field,
  form,
  helperText,
  label = '',
  placeholder = '',
  required = false,
  type = 'text',
  autocomplete = 'off',
  multiline = false,
  inputRef,
}) {
  const { formHelperText, showError } = useFormikFormField({
    field,
    form,
    helperText,
  });

  return (
    <FormControl error={showError} required={required}>
      {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
      {multiline ? (
        <Textarea
          {...field}
          autoComplete={autocomplete}
          placeholder={placeholder}
          ref={inputRef}
        />
      ) : (
        <Input
          {...field}
          type={type}
          autoComplete={autocomplete}
          placeholder={placeholder}
          ref={inputRef}
        />
      )}
      <FormHelperText>{formHelperText}</FormHelperText>
    </FormControl>
  );
}

export default FormikFormField;
