import { SubscribeEmailBody } from 'pages/api/next/subscribe';

export const NEWSLETTER_CONTACT_LIST_ID = 179340;

export const contactListSubscribe = async (
  email: string,
  contactListId?: number
) => {
  const body: SubscribeEmailBody = {
    email,
    contactListId,
  };

  const response = await fetch('/api/next/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(response.statusText);
  }
};
