import React, { ReactNode } from 'react';
import { FormHelperText } from '@heycater/design-system';
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';

interface Props extends CheckboxProps {
  name: string;
  label: ReactNode;
}

const FormikCheckbox = ({ label, name, ...checkboxProps }: Props) => {
  const [{ value, ...formikFieldProps }, { error, touched }] = useField(name);
  const showError = Boolean(error && touched);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...formikFieldProps}
            aria-checked={value}
            checked={value}
            {...checkboxProps}
          />
        }
        label={label}
      />
      {showError && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default FormikCheckbox;
